import React from "react";
import { useContext } from "react";
import { PageContext } from "../../lib/context";
import NotesA from "./components/notes-a";
import NotesB from "./components/notes-b";
import NotesC from "./components/notes-c";
import RegistrationFormA from "./components/registration-form-a";
import { Button } from "antd";
import { FileSearchOutlined } from "@ant-design/icons";
import RegistrationFormB from "./components/registration-form-b";
import RegistrationFormC from "./components/registration-form-c";

const RegistrationView = (props) => {
  const {
    isAgree,
    setIsAgree,
    handleClassificationChange,
    classification,
    category,
    setCategory,
  } = useContext(PageContext);
  return (
    <div className="flex flex-col h-screen items-center mb-56">
      <div className="flex flex-col items-center self-center w-2/5">
        <img
          src={require("../../assets/sugbo-negosyo.jpg")}
          className="h-32 rounded-lg"
        />
        <div>
          <p className="text-center text-2xl">SUGBO NEGOSYO</p>
        </div>
      </div>
      <div className="mb-20">
        <p>In partnership with</p>
        <div className="flex flex-row">
          <img
            src={require("../../assets/cebu-province-logo.jpg")}
            className="h-16 rounded-lg"
          />
          <img
            src={require("../../assets/wepay-square.png")}
            className="h-16 rounded-lg"
          />
        </div>
      </div>
      {!category && (
        <div className="w-2/4">
          <p className="text-base">Select Category Form to fill up.</p>
          <div className="flex flex-row justify-evenly mt-10">
            <div className="cursor-pointer" onClick={() => setCategory("a")}>
              <FileSearchOutlined className="text-6xl text-blue-400" />
              <p>Category A</p>
            </div>
            <div className="cursor-pointer" onClick={() => setCategory("b")}>
              <FileSearchOutlined className="text-6xl text-green-400" />
              <p>Category B</p>
            </div>
            <div className="cursor-pointer" onClick={() => setCategory("c")}>
              <FileSearchOutlined className="text-6xl text-red-400" />
              <p>Category C</p>
            </div>
          </div>
          <div className="mt-10">
            <p>
              Category A -{" "}
              <span className="font-bold text-blue-400">
                NEGOSYONG PADAYONON CATEGORY
              </span>
            </p>
            <p>
              Category B -{" "}
              <span className="font-bold text-green-400">
                PRODUKTONG SUGBOANON CATEGORY
              </span>
            </p>
            <p>
              Category C -{" "}
              <span className="font-bold text-red-400">
                MGA SERBISYO UG GINAGMAYNG PATIGAYON CATEGORY
              </span>
            </p>
          </div>
        </div>
      )}
      {category === "a" && (
        <div className="w-2/4 text-left" style={{ fontSize: 15 }}>
          {!isAgree && (
            <p>
              Category A -{" "}
              <span className="font-bold text-blue-400">
                NEGOSYONG PADAYONON CATEGORY
              </span>
            </p>
          )}
          {!isAgree && <NotesA />}
          {isAgree && <RegistrationFormA />}
        </div>
      )}
      {category === "b" && (
        <div className="w-2/4 text-left" style={{ fontSize: 15 }}>
          {!isAgree && (
            <p>
              Category B -{" "}
              <span className="font-bold text-green-400">
                PRODUKTONG SUGBOANON CATEGORY
              </span>
            </p>
          )}
          {!isAgree && <NotesB />}
          {isAgree && <RegistrationFormB />}
        </div>
      )}
      {category === "c" && (
        <div className="w-2/4 text-left" style={{ fontSize: 15 }}>
          {!isAgree && (
            <p>
              Category C -{" "}
              <span className="font-bold text-red-400">
                MGA SERBISYO UG GINAGMAYNG PATIGAYON CATEGORY
              </span>
            </p>
          )}
          {!isAgree && <NotesC/>}
          {isAgree && <RegistrationFormC />}
        </div>
      )}
      <div className="mt-56">
        <p>Copyright @WePay 2020</p>
      </div>
    </div>
  );
};

export default RegistrationView;
