import gql from "graphql-tag";

export const UPSERT_USERS = gql`
mutation MyMutation($object: [users_insert_input!]!) {
  insert_users(objects: $object) {
    returning {
      id
    }
  }
}
`

export const UPSERT_USER_ITEMS = gql`
mutation MyMutation($object: [user_items_insert_input!]!) {
  insert_user_items(objects: $object) {
    affected_rows
  }
}
`

export const UPSERT_USER_BUSINESS_CERTIFICATES = gql`
mutation MyMutation($object: [user_business_certificates_insert_input!]!) {
  insert_user_business_certificates(objects: $object) {
    affected_rows
  }
}
`

export const INSERT_BUSINESS_CERTIFICATES_IMAGES = gql`
mutation MyMutation($object: [user_business_certificates_images_insert_input!]!) {
  insert_user_business_certificates_images(objects: $object) {
    affected_rows
  }
}
`