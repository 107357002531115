import React, { useContext } from "react";
import {
  Form,
  Input,
  Select,
  DatePicker,
  InputNumber,
  Radio,
  Checkbox,
  Button,
  Space,
  Upload,
  Spin,
  notification
} from "antd";
import {
  UserOutlined,
  InfoCircleOutlined,
  MinusCircleOutlined,
  PlusOutlined,
  UploadOutlined
} from "@ant-design/icons";
import { useState } from "react";
import { PageContext } from "../../../lib/context";
import { Offices } from "./offices";

const RegistrationFormC = (props) => {
  const {
    handleClassificationChange,
    classification,
    handleFinishForm,
    setIsAgree,
    handleUserImageChange,
    handleUserIdImageChange,
    loading,
    encodeGroup,
    handleUserPermitsChange,
    form
  } = useContext(PageContext);

  const classificationOptions = [
    "Traditional/subsistence fisherfolk",
    "Farmers and landless rural workers",
    "Urban poor",
    "Indigenous peoples",
    "Formal labor and migrant workers",
    "Workers in the informal sector",
    "Women",
    "Out-of-school youth (above 18 years old)",
    "Senior citizens",
    "Persons with disabilities",
    "Persons deprived of liberty (PDL)***",
    "Drug surrenderees",
    "Victims of disasters and calamities, including health disasters (COVID-19)",
    "Surfacers (former rebels)",
  ];

  const registrationCertificates = [
    "DTI Business name permit",
    "Mayors Business permit",
    "Barangay Business permit",
    "BIR Registration",
    "Barangay Micro Business Enterprise (BMBE) Registration",
  ];

  const validIDs = [
    "UMID",
    "TIN ID",
    "Drivers License",
    "Postal ID",
    "Voters ID",
    "Passport",
    "Senior Citizen ID",
    "School ID (if student)",
  ];

  const required = (name) => {
    return [
      {
        required: true,
        message: `${name} is required`,
      },
    ];
  };

  const handleFinishFailed = () => {
    notification.error({
      message: "You have missed some fields.",
      description: "Fields with * are required.",
    });
  };

  return (
    <div className="mb-56">
      <p
        className="text-blue-400 cursor-pointer mb-5"
        onClick={() => setIsAgree(false)}
      >
        Back
      </p>
      <p className="text-xl font-bold">Registration Category C - <span className="font-bold text-red-400">MGA SERBISYO UG GINAGMAYNG PATIGAYON CATEGORY</span></p>
      <Form form={form} layout="vertical" onFinish={handleFinishForm} onFinishFailed={handleFinishFailed}>
        <p className="font-bold">Personal information:</p>
        <div className="mb-5 mt-5">
          <Upload beforeUpload={handleUserImageChange}>
            <Button icon={<UploadOutlined />}>Upload Selfie Picture</Button>
          </Upload>
        </div>
        <div className="flex flex-row w-full">
          <Form.Item
            required
            rules={required("Office")}
            label="Choose Office"
            name="assigned_group"
            className="w-full mr-5"
          >
            {encodeGroup === null ? (
              <Select>
                {Offices.map((item) => (
                  <Select.Option value={item?.OFFICE}>
                    {item?.OFFICE}
                  </Select.Option>
                ))}
              </Select>
            ) : encodeGroup === "wepay" ? (
              <Select defaultValue={encodeGroup !== null && encodeGroup}>
                <Select.Option value={"wepay"}>Wepay</Select.Option>
              </Select>
            ) : (
              <Select>
                {Offices.map((item) => (
                  <Select.Option value={item?.OFFICE}>
                    {item?.OFFICE}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </div>
        <div className="flex flex-row w-full">
          <Form.Item
            required
            rules={required("First name")}
            label="First name"
            name="first_name"
            className="w-full mr-5"
          >
            <Input placeholder="Juan" prefix={<UserOutlined />} />
          </Form.Item>
          <Form.Item
            required
            rules={required("Last name")}
            label="Last name"
            name="last_name"
            className="w-full mr-5"
          >
            <Input placeholder="Dela Cruz" prefix={<UserOutlined />} />
          </Form.Item>
        </div>
        <div className="flex flex-row">
          <Form.Item
            required
            rules={required("Gender")}
            label="Gender"
            className="w-full mr-5"
            name="gender"
          >
            <Select>
              <Select.Option value="male">Male</Select.Option>
              <Select.Option value="female">Female</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            required
            rules={required("Civil status")}
            label="Civil Status"
            name="civil_status"
            className="w-full mr-5"
          >
            <Select>
              <Select.Option value="single">Single</Select.Option>
              <Select.Option value="married">Married</Select.Option>
              <Select.Option value="separated">Separated</Select.Option>
              <Select.Option value="widowed">Widowed</Select.Option>
            </Select>
          </Form.Item>
        </div>
        <div className="flex flex-row">
          <Form.Item
            required
            rules={required("Birth date")}
            label="Date of birth"
            name="birth_date"
            className="w-full mr-5"
          >
            <DatePicker className="w-full" />
          </Form.Item>
          <Form.Item
            required
            rules={required("Place of birth")}
            label="Place of birth"
            name="place_of_birth"
            className="w-full mr-5"
          >
            <Input placeholder="Location" prefix={<UserOutlined />} />
          </Form.Item>
        </div>
        <div className="flex flex-row">
          <Form.Item
            required
            rules={required("Family count")}
            label="Family members count"
            name="family_count"
            className="w-full mr-5"
          >
            <InputNumber className="w-full" placeholder="ex. 3" />
          </Form.Item>
          <Form.Item
            required
            rules={required("Total income")}
            label="Total income per month"
            name="monthly_income"
            className="w-full mr-5"
          >
            <InputNumber
              placeholder="ex. 30000"
              className="w-full"
              prefix={<UserOutlined />}
            />
          </Form.Item>
        </div>
        <p className="font-bold">Complete address:</p>
        <div className="flex flex-row">
          <Form.Item
            required
            rules={required("House no")}
            label="House no. or Lot/blk/Phase"
            name="house_no"
            className="w-full mr-5"
          >
            <Input placeholder="ex. 0190" prefix={<InfoCircleOutlined />} />
          </Form.Item>
          <Form.Item
            required
            rules={required("Floor no.")}
            label="Floor no. "
            name="floor_no"
            className="w-full mr-5"
          >
            <Input placeholder="ex. 4" prefix={<InfoCircleOutlined />} />
          </Form.Item>
          <Form.Item
            required
            rules={required("Building name is required")}
            label="Building Apartment/name"
            name="building_apartment_name"
            className="w-full mr-5"
          >
            <Input
              placeholder="ex. Deca Homes"
              prefix={<InfoCircleOutlined />}
            />
          </Form.Item>
        </div>
        <div className="flex flex-row">
          <Form.Item
            required
            rules={required("Street name")}
            label="Street name"
            name="street_name"
            className="w-full mr-5"
          >
            <Input
              placeholder="ex. Bonifacio street"
              prefix={<InfoCircleOutlined />}
            />
          </Form.Item>
          <Form.Item
            required
            rules={required("Sitio or purok")}
            label="Sitio or Purok"
            name="sitio_or_purok"
            className="w-full mr-5"
          >
            <Input placeholder="ex. Mayahay" prefix={<InfoCircleOutlined />} />
          </Form.Item>
          <Form.Item
            label="Subdivision"
            name="subdivision"
            className="w-full mr-5"
          >
            <Input
              placeholder="ex. Maria luisa"
              prefix={<InfoCircleOutlined />}
            />
          </Form.Item>
          <Form.Item
            required
            rules={required("Barangay")}
            label="Barangay"
            name="barangay"
            className="w-full mr-5"
          >
            <Input placeholder="ex. Lahug" prefix={<InfoCircleOutlined />} />
          </Form.Item>
          <Form.Item
            required
            rules={required("City/Municipality")}
            label="City/Municipality"
            name="city_municipality"
            className="w-full mr-5"
          >
            <Input placeholder="ex. Cebu" prefix={<InfoCircleOutlined />} />
          </Form.Item>
        </div>
        <p className="font-bold mt-5">Your contact information:</p>
        <div className="flex flex-row">
          <Form.Item
            required
            rules={required("Cellphone number")}
            label="Cellphone number"
            name="mobile_number"
            className="w-full mr-5"
          >
            <Input placeholder="639XXXXXXXXX" className="w-full" />
          </Form.Item>
          <Form.Item
            required
            rules={required("Email address")}
            label="Email address"
            name="email"
            className="w-full mr-5"
          >
            <Input placeholder="" className="w-full" />
          </Form.Item>
          <Form.Item
            label="Landline number"
            name="landline_number"
            className="w-full mr-5"
          >
            <Input placeholder="" className="w-full" />
          </Form.Item>
          <Form.Item
            label="FB messenger"
            name="fb_messenger"
            className="w-full mr-5"
          >
            <Input placeholder="" className="w-full" />
          </Form.Item>
        </div>
        <p className="font-bold mt-5">Information about your spouse:</p>
        <div className="flex flex-row">
          <Form.Item
            label="Name of spouse"
            name="name_of_spouse"
            className="w-full mr-5"
          >
            <Input placeholder="" className="w-full" />
          </Form.Item>
          <Form.Item
            label="Spouse birth date"
            name="spouse_birth_date"
            className="w-full mr-5"
          >
            <DatePicker className="w-full" />
          </Form.Item>
          <Form.Item
            label="Contact number"
            name="spouse_contact_number"
            className="w-full mr-5"
          >
            <Input placeholder="" className="w-full" />
          </Form.Item>
        </div>
        <p className="font-bold mt-5">Details about your business:</p>
        <div className="flex flex-row">
          <Form.Item
            required
            rules={required("Answer")}
            label="Currently have business?"
            name="has_business"
            className="w-full mr-5"
          >
            <Select placeholder="Yes/No">
              <Select.Option value="Yes">Yes</Select.Option>
              <Select.Option value="No">No</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Is your business registered?"
            name="is_business_registered"
            className="w-full mr-5"
          >
            <Select placeholder="Yes/No">
              <Select.Option value="Yes">Yes</Select.Option>
              <Select.Option value="No">No</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Estimated Daily Sales"
            name="estimated_daily_sales"
            className="w-full mr-5"
          >
            <Input placeholder="ex. 500" className="w-full" />
          </Form.Item>
        </div>
        <div className="flex flex-row">
          <Form.Item
            label="If registered, what certificates do you have now? (check which applies)"
            name="business_certificates"
            className="w-full mr-5"
          >
            <Checkbox.Group options={registrationCertificates} />
          </Form.Item>
        </div>
        <div className="mb-5">
          <Upload beforeUpload={handleUserPermitsChange} accept=".png,.jpg,.jpeg,.pdf" multiple={true}>
            <Button icon={<UploadOutlined />}>Upload Certificates Permits</Button>
          </Upload>
        </div>
        <div className="flex flex-row">
          <Form.Item
            label="How many Month/s or Year/s is your business running."
            name="business_months_years"
            className="w-full mr-5"
          >
            <Input placeholder="ex. 3 months, 1 year, 5 years" />
          </Form.Item>
          <Form.Item
            label="Business address"
            name="business_address"
            className="w-full mr-5"
          >
            <Input placeholder="Complete address of your business" />
          </Form.Item>
        </div>
        <div className="flex flex-row">
          <Form.Item
            label="Do you own the lot where your business resides?"
            name="is_lot_owned"
            className="w-full mr-5"
          >
            <Select placeholder="Yes/No">
              <Select.Option value="Yes">Yes</Select.Option>
              <Select.Option value="No">No</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Number of employees"
            name="number_of_employees"
            className="w-full mr-5"
          >
            <InputNumber placeholder="ex. 5" className="w-full" />
          </Form.Item>
        </div>
        <p className="font-bold mt-5">
          For people planning to build a new business:
        </p>
        <div className="flex flex-row">
          <Form.Item
            required
            rules={required("Answer")}
            label="If you are given the financial support, what business will you choose?"
            name="new_business_name"
            className="w-full mr-5"
          >
            <Input placeholder="ex. Sari-sari store" className="w-full" />
          </Form.Item>
        </div>
        <div className="flex flex-row">
          <Form.Item
            required
            rules={required("Answer")}
            label="Why did you choose this business?"
            name="chosen_business_reason"
            className="w-full mr-5"
          >
            <Input.TextArea
              placeholder="Details why you chose this business"
              className="w-full"
            />
          </Form.Item>
        </div>
        <div className="flex flex-row">
          <Form.Item
            required
            rules={required("Answer")}
            label="Why do you think this will be a successful business?"
            name="chosen_business_will_be_successful_reason"
            className="w-full mr-5"
          >
            <Input.TextArea
              placeholder="Reason you think it will be successful"
              className="w-full"
            />
          </Form.Item>
        </div>
        <div className="flex flex-row">
          <Form.Item
            required
            rules={required("Answer")}
            label="Have you already received any financial support to improve your business?"
            name="has_received_financial_support"
            className="w-full mr-5"
          >
            <Select placeholder="Yes/No">
              <Select.Option value="Yes">Yes</Select.Option>
              <Select.Option value="No">No</Select.Option>
            </Select>
          </Form.Item>
        </div>
        <div className="flex flex-row">
          <Form.Item
            label="Date received the financial support?"
            name="receive_date"
            className="w-full mr-5"
          >
            <DatePicker className="w-full" />
          </Form.Item>
          <Form.Item
            label="Received Amount"
            name="receive_amount"
            className="w-full mr-5"
          >
            <Input placeholder="ex. 5000" className="w-full" />
          </Form.Item>
        </div>
        <div className="flex flex-row">
          <Form.Item
            label="Agency who gave you the financial support?"
            name="receive_agency"
            className="w-full mr-5"
          >
            <Input className="w-full" />
          </Form.Item>
          <Form.Item
            label="What did you do to the financial support you received?"
            name="receive_usage"
            className="w-full mr-5"
          >
            <Input.TextArea placeholder="Answer" className="w-full" />
          </Form.Item>
        </div>
        <div className="flex flex-row">
          <Form.Item
            required
            rules={required("Answer")}
            label="Why would our program help you with your business?"
            name="reason_to_be_helped"
            className="w-full mr-5"
          >
            <Input.TextArea placeholder="" className="w-full" />
          </Form.Item>
        </div>
        <div className="flex flex-row">
          <Form.Item
            required
            rules={required("Answer")}
            label="What are your experiences, knowledge, trainings and skills that would help you on your business?"
            name="experiences"
            className="w-full mr-5"
          >
            <Input.TextArea placeholder="" className="w-full" />
          </Form.Item>
        </div>
        <p className="font-bold mt-5">
          Things you need for your business
        </p>
        <Form.List name="items">
          {(fields, { add, remove }) => {
            return (
              <div className="flex flex-col">
                {fields.map((field) => (
                  <Space
                    key={field.key}
                    style={{ display: "flex", marginBottom: 8 }}
                    align="start"
                  >
                    <Form.Item
                      {...field}
                      name={[field.name, "item"]}
                      fieldKey={[field.fieldKey, "item"]}
                      rules={[{ required: true, message: "Missing item name" }]}
                    >
                      <Input placeholder="Item" />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, "quantity"]}
                      fieldKey={[field.fieldKey, "quantity"]}
                      rules={[{ required: true, message: "Missing quantity" }]}
                    >
                      <InputNumber placeholder="Quantity" />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, "unit_price"]}
                      fieldKey={[field.fieldKey, "unit_price"]}
                      rules={[
                        { required: true, message: "Missing Unit price" },
                      ]}
                    >
                      <InputNumber placeholder="Unit price" />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, "cost"]}
                      fieldKey={[field.fieldKey, "cost"]}
                      rules={[{ required: true, message: "Missing cost" }]}
                    >
                      <InputNumber placeholder="Cost" />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, "suggested_supplier_vendor"]}
                      fieldKey={[field.fieldKey, "suggested_supplier_vendor"]}
                      rules={[
                        {
                          required: true,
                          message: "Suggested supplier/vendor",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Suggested supplier/vendor"
                        className="w-full"
                      />
                    </Form.Item>

                    <MinusCircleOutlined
                      onClick={() => {
                        remove(field.name);
                      }}
                    />
                  </Space>
                ))}

                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => {
                      add();
                    }}
                    block
                  >
                    <PlusOutlined /> Add Item
                  </Button>
                </Form.Item>
              </div>
            );
          }}
        </Form.List>
        <p className="font-bold mt-5">Valid ID information:</p>
        <div className="mb-5 mt-5">
          <Upload beforeUpload={handleUserIdImageChange}>
            <Button icon={<UploadOutlined />}>Upload picture of your valid ID</Button>
          </Upload>
        </div>
        <div className="flex flex-row">
          <Form.Item
            required
            rules={required("Answer")}
            label="Do you have a valid ID?"
            name="has_valid_id"
            className="w-full mr-5"
          >
            <Select placeholder="Yes/No">
              <Select.Option value="Yes">Yes</Select.Option>
              <Select.Option value="No">No</Select.Option>
            </Select>
          </Form.Item>
        </div>
        <div className="flex flex-row">
          <Form.Item
            required
            rules={required("Valid ID")}
            label="What kind of valid ID do you have?"
            name="id_type"
            className="w-full mr-5"
          >
            <Select placeholder="Select ID">
              {validIDs.map((id, key) => (
                <Select.Option value={id}>{id}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className="flex flex-row">
          <Form.Item
            required
            rules={required("Id number")}
            label="ID number?"
            name="id_number"
            className="w-full mr-5"
          >
            <Input placeholder="" className="w-full" />
          </Form.Item>
          <Form.Item
            label="ID date expiry?"
            name="id_expiry"
            className="w-full mr-5"
          >
            <DatePicker className="w-full" />
          </Form.Item>
          <Form.Item
            required
            rules={required("Agency issuer")}
            label="Agency ID was issued?"
            name="id_issuer"
            className="w-full mr-5"
          >
            <Input placeholder="" className="w-full" />
          </Form.Item>
        </div>
        <p>
          By clicking the submit registration you are agreeing to the Terms and
          Conditions, Privacy Policy of this site.
        </p>
        <Button htmlType="submit" type="primary" className="text-center" disabled={loading}>
          {loading ? <Spin/> : 'Submit Registration'}
        </Button>
      </Form>
    </div>
  );
};

export default RegistrationFormC;
