import React, { useEffect, useState } from "react";
import { Input, Form, Button, notification, message } from "antd";
import RegistrationView from "./view";
import { PageContext } from "../../lib/context";
import {
  INSERT_BUSINESS_CERTIFICATES_IMAGES,
  UPSERT_USERS,
  UPSERT_USER_BUSINESS_CERTIFICATES,
  UPSERT_USER_ITEMS,
} from "../../lib/mutations";
import { useMutation } from "@apollo/react-hooks";
import S3 from "react-aws-s3";
import moment from "moment";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";

const config = {
  bucketName: process.env.REACT_APP_BUCKET_NAME,
  dirName: process.env.REACT_APP_DIR_NAME /* optional */,
  region: process.env.REACT_APP_REGION,
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_KEY_ACCESS,
  s3Url: process.env.REACT_APP_S3_URL,
};

const Registration = (props) => {
  const [form] = Form.useForm();
  const location = useLocation();
  const [isAgree, setIsAgree] = useState(false);
  const [classification, setClassification] = useState(0);
  const [profileImage, setProfileImage] = useState(null);
  const [certificateFiles, setCertificateFiles] = useState(null);
  const [validIdImage, setValidIdImage] = useState(null);
  const [images, setImages] = useState({
    userPicture: null,
    userId: null,
    userPermits: [],
  });
  const [category, setCategory] = useState(null);
  const [loading, setLoading] = useState(false);
  const [encodeGroup, setEncodeGroup] = useState(null);

  const S3Client = new S3(config);

  useEffect(() => {
    let params = new URLSearchParams(location.search);
    setEncodeGroup(params.get("encode_group"));
    form.setFieldsValue({assigned_group: params.get("encode_group")});
  }, [props]);

  const [upsertUsers] = useMutation(UPSERT_USERS);
  const [upsertCertificates] = useMutation(UPSERT_USER_BUSINESS_CERTIFICATES);
  const [upsertItems] = useMutation(UPSERT_USER_ITEMS);
  const [insertCertificatesImages] = useMutation(
    INSERT_BUSINESS_CERTIFICATES_IMAGES
  );

  const handleClassificationChange = (e) => {
    setClassification(e.target.value);
  };

  const handleFinishForm = async (data) => {
    let params = new URLSearchParams(location.search);
    setEncodeGroup(params.get("encode_group"));
    setLoading(true);
    const business_certificates = data.business_certificates;
    const items = data.items;
    delete data["business_certificates"];
    delete data["items"];

    if (!images.userPicture) {
      notification.error({
        message: "Must upload your picture",
      });
      setLoading(false);
      return;
    }

    if (!images.userId) {
      notification.error({
        message: "Must upload a picture of your valid ID",
      });
      setLoading(false);
      return;
    }

    if (!items?.length) {
      notification.error({
        message: "Must list down all Items and Materials needed.",
      });
      setLoading(false);
      return;
    }

    const { location: user_picture } = await S3Client.uploadFile(
      images.userPicture,
      "pic-" + moment().format("YYYY-MM-DD h:m:s").toString()
    );
    const { location: user_id_picture } = await S3Client.uploadFile(
      images.userId,
      "id-" + moment().format("YYYY-MM-DD h:m:s").toString()
    );

    const res = await upsertUsers({
      variables: {
        object: [
          {
            ...data,
            category,
            user_picture,
            user_id_picture,
            status: encodeGroup !== null ? "accept" : "pending",
          },
        ],
      },
    });
    const id = res?.data?.insert_users?.returning?.[0]?.id;
    if (id) {
      // INSERT ITEMS
      const new_items_object = items.map(function (el) {
        var o = Object.assign({}, el);
        o.user_id = id;
        return o;
      });
      await upsertItems({
        variables: {
          object: [...new_items_object],
        },
      });

      // INSERT CERTIFICATES
      if (business_certificates?.length) {
        const certificates_object = [];
        business_certificates.map((name) => {
          certificates_object.push({
            user_id: id,
            name,
          });
        });
        await upsertCertificates({
          variables: {
            object: [...certificates_object],
          },
        });
      }

      // INSERT CERTIFICATES IMAGES
      const uploaded_permits_images = [];
      if (images.userPermits.length > 0) {
        console.log("images-permits", images.userPermits);
        images.userPermits.map(async (file, key) => {
          const { location: user_permit_picture } = await S3Client.uploadFile(
            file,
            "permit-" + moment().format("YYYY-MM-DD h:m:s").toString()
          );
          uploaded_permits_images.push(user_permit_picture);

          if (key === images.userPermits.length - 1) {
            console.log("uploads", uploaded_permits_images);
            const cert_images_object = [];
            uploaded_permits_images.map((item) => {
              cert_images_object.push({
                user_id: id,
                url: item,
              });
            });

            await insertCertificatesImages({
              variables: {
                object: [...cert_images_object],
              },
            });

            notification.success({
              message: "Registration sent.",
              description:
                "You have successfully registered and is now pending for review.",
            });
            form.resetFields();
            form.setFieldsValue({assigned_group: params.get("encode_group")});
            setIsAgree(false);
            setLoading(false);
            return;
          }
        });
      } else {
        notification.success({
          message: "Registration sent.",
          description:
            "You have successfully registered and is now pending for review.",
        });
        form.resetFields();
        form.setFieldsValue({assigned_group: params.get("encode_group")});
        setIsAgree(false);
        setLoading(false);
        return;
      }
    } else {
      notification.error({
        message: "Registration failed.",
        description: "Something went wrong.",
      });
      setLoading(false);
      return;
    }
  };

  const handleUserImageChange = async (file, fileList) => {
    setImages({
      ...images,
      userPicture: file,
    });
  };

  const handleUserIdImageChange = async (file, fileList) => {
    setImages({
      ...images,
      userId: file,
    });
  };

  const handleUserPermitsChange = async (file, fileList) => {
    setImages({
      ...images,
      userPermits: [...images.userPermits, ...fileList],
    });
    console.log("filelist", fileList);
  };

  const pageValues = {
    form,
    isAgree,
    setIsAgree,
    handleClassificationChange,
    classification,
    handleFinishForm,
    category,
    setCategory,
    handleUserImageChange,
    handleUserIdImageChange,
    loading,
    encodeGroup,
    handleUserPermitsChange,
  };

  return (
    <PageContext.Provider value={pageValues}>
      <RegistrationView />
    </PageContext.Provider>
  );
};

export default Registration;
