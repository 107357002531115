import gql from "graphql-tag";

export const USER_INFO = gql`
subscription MyInfo {
  users {
    id
    balance
    created_at
    email
    type
    user_informations {
      firstname
      lastname
      gender
    }
  }
}
`

export const LIVE_CASINO_GAMES = gql`
subscription LiveGames {
  games(where: {status: {_neq: "GAME_STOPPED"}}) {
    name
    status
    id
    type
    user_information {
      firstname
      lastname
    }
  }
}
`

export const GAME_DATA = gql`
subscription GameData($game_id: uuid) {
  games(where: {id: {_eq: $game_id}}) {
    name
    status
    id
    link
    status
    game_id
    red_and_white_game_results {
      id
      result
    }
    user_information {
      lastname
      firstname
    }
  }
}
`

export const GAME_RESULTS = gql`
subscription GameResults($game_id: uuid) {
  red_and_white_game_results(where: {game_id: {_eq: $game_id}}, order_by: {created_at: asc}) {
    id
    result
    created_at
  }
}
`

export const GAME_BETS = gql`
subscription GameBets($game_id: uuid) {
  bets(where: {game_id: {_eq: $game_id}}) {
    id
    status
    game_id
    user_id
    bet_value
    type
  }
}
`

export const ASSIGNED_GAMES = gql`
subscription MyGames {
  games {
    id
    name
    status
    type
    user_information {
      firstname
      lastname
    }
  }
}
`

export const USER_BET_RESULTS = gql`
subscription MyGameBetResults($game_id: uuid) {
  user_bet_results(order_by: {created_at: desc}, where: {game_id: {_eq: $game_id}}) {
    id
    status
    amount
    game_result_id
    created_at
    bet {
      type
      bet_value
    }
  }
}
`