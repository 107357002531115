import React from "react";
import { Button } from "antd";
import { useContext } from "react";
import { PageContext } from "../../../lib/context";

const NotesA = (props) => {
  const { setIsAgree, setCategory } = useContext(PageContext);
  return (
    <>
      <p className="font-bold">MGA LAGDA SA PROGRAMA</p>
      <p>
        Kining maong programa gimugna subay sa tinguha sa pangagamhanan sa
        Lalawigan sa Sugbo nga palagiskon pagbalik ang ekonomiya sa atong
        pinalanggang probinsya. Aron kini magmalapuson, ania ang pipila ka mga
        lagda sa atong programa:
      </p>
      <ul>
        <li>
          1. Kini nga kategoriya sa atong programa, NEGOSYONG PADAYONON, abli sa
          bisan kinsa nga makapasar sa mosunod nga mga rekisito:
        </li>
        <li className="ml-4">a. Rehistradong botante sa Probinsya sa Sugbo</li>
        <li className="ml-4">
          b. Nag-edad og 18 anyos pataas atol sa pagpangapply
        </li>
        <li className="ml-4">
          c. Nanag-iya na daan og negosyo nga
          <li className="ml-8">
            • Adunay balido nga mayor’s permit ug DTI registration
          </li>
          <li className="ml-8">
            • Natukod na o nag-dagan na sugod o mas sayo pa sa January 2018
          </li>
          <li className="ml-8">
            • Giila nga usa ka micro business enterprise, diin ang puhonan dili
            molapas sa Php 3,000,000.00 sumala sa RA 9178, Barangay Micro
            Business Enterprises (BMBEs) Act
          </li>
          <li className="ml-8">
            • Naapektahan pag-ayo sa pandemya nga nanginahanglan kini og puhonan
            aron makapangabli og balik o aron mag-usab sa pamaagi sa pagpadagan
            sa negosyo
          </li>
        </li>
        <li>
          2. Kinahanglan nga ang mga raw materials o sagol sa pag-gama sa mga
          produkto, apil ang packaging niini, dinhi ra kuhaon o paliton sa
          Sugbo.
        </li>
        <li>
          3. Usa (1) lang ka aplikante kada panimalay ang mahimong mo-apply.
        </li>
        <li>
          4. Alang sa mga aplikante nga aduna nay negosyo, ang madawat nga seed
          capital dili mamahimong gamiton aron sa pagpausab o pagpanindot sa
          tindahan.
        </li>
      </ul>
      <p className="font-bold mt-5">REQUIREMENTS</p>
      <ul>
        <li>• Application Form</li>
        <li>• Voter’s ID or Voter’s Certification issued by COMELEC</li>
        <li>• Valid government-issued ID with photo</li>
        <li>• Mayor’s Permit</li>
        <li>• DTI Registration</li>
        <li>• Picture of business establishment</li>
        <li>• Picture of the product/s</li>
      </ul>
      <p className="font-bold mt-5">IMPORTANTENG PAHIMANGNO:</p>
      <ul>
        <li>
          1. Kinahanglan tubagon ang tanang pangutana sa kada blangko niining
          application form.
        </li>
        <li>2. Mga pamaagi sa pagsubmit sa aplikasyon:</li>
        <li className="ml-4">
          a. Handcarry: Isubmit sa inyong Local Social Welfare Development
          Office o sa inyong Barangay Hall. Kuhaa ang pangalan sa empleyado nga
          maoy nakadawat sa imong aplikasyon ug ang iyang contact number, ug
          itala ang petsa ug oras sa imong pagsubmit.
        </li>
        <li className="ml-4">
          b. Online: Kuhai og picture ang application form ug mga attachments ug
          ipadala niining maong email address: [insert official e-mail address
          of Board Member]. Siguraduha nga maklaro ug mabasa ang mga tubag sa
          imong pictures nga i-submit. Sa Subject line, i-type ang [Imong
          pangalan], [Barangay, City/Municipality] – Sugbo Negosyo Application
          Example:{" "}
          <span className="font-bold">
            JUANA DELA CRUZ, PITALO, SAN FERNANDO – Sugbo Negosyo Application
          </span>
        </li>
        <li>
          3. Kon mag-follow up, palihug og tawag sa opisina ni Board Member
          [insert name] sa mosunod nga mga numero: [insert contact numbers].
        </li>
      </ul>
      <div className="flex justify-end">
        <p
          className="text-blue-400 cursor-pointer mt-5 self-end mr-5"
          onClick={() => setCategory(null)}
        >
          Back
        </p>
        <Button
          type="primary"
          className="mt-5 self-end"
          onClick={() => setIsAgree(true)}
        >
          I agree, Register now
        </Button>
      </div>
    </>
  );
};

export default NotesA;
